import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const ShatterAnimation: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}>
    <SEO title="Shatter Animation" />
    <h1>Shatter Animation</h1>
    <p>
      Using After Effects I had to create a 20 second animation which was to
      follow the theme “Time goes by…”. To expand upon this theme I used some of
      my own experience to create a scripted dialogue. Using animation I then
      tried to emphasize the impact of the words and create something that would
      be appealing to watch. I also took great care while storyboarding to make
      sure everything hit the strict 20 second length.
    </p>
    <ResponsiveIFrame src="https://player.vimeo.com/video/133916581" />
  </StandardLayout>
)

export default ShatterAnimation
